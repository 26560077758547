// import { getStorage, ref, uploadBytes, getDownloadURL  } from "firebase/storage";


export default {
  namespaced: true,
  state() {
    return {
      lastFetched: null,
      rentals: [],
    };
  },
  mutations: {
    addRental(state, payload) {
      state.rentals.push(payload);
    },
    setRentals(state, payload) {
      state.rentals = payload;
    },
    setFetchTimestamp(state) {
      state.lastFetched = new Date().getTime()
    }
  },
  actions: {
    async addRental(context, payload) {
      // const rental = {
      //   ...paylaod
      // };

      // get the details of owner who is adding the rental and who is online currently
      const userId = context.rootGetters.userId;
      const email = context.rootGetters.userEmail


      const token = context.rootGetters.token;

      const ownerDetails = await fetch('https://jazira-rentals-default-rtdb.firebaseio.com/owners.json?auth=' + token)

      const responseOwnerDetailsData = await ownerDetails.json()

      // console.log('all owners', responseOwnerDetailsData)

      let owner = null
      // console.log('The email', email)
      for (const ownerKey in responseOwnerDetailsData) {
        if (responseOwnerDetailsData[ownerKey].email === email) {
          owner = responseOwnerDetailsData[ownerKey]
        }
      }
      // console.log('this is the owner', owner)
      let country = 'Uganda'
      let city = 'Kampala'

      if(payload.country) {
        country = payload.country
      }

      if(payload.city) {
        city = payload.city
      }
      const rental = {
        ownerName: owner.firstName + ' ' + owner.lastName,
        ownerPhoneNumber: owner.phoneNumber,
        buildingName: payload.buildingName,
        apartmentNumber: payload.apartmentNumber,
        description: payload.description,
        price: payload.price,
        location: payload.location,
        numberOfBedrooms: payload.numberOfBedrooms,
        numberOfBathrooms: payload.numberOfBathrooms,
        sittingRoom: payload.sittingRoom,
        emailAddress: email,
        ownerId: userId,
        approved: payload.approved,
        booked: payload.booked,
        contactPersonName: payload.contactPersonName,
        contactPersonPhone: payload.contactPersonPhone,
        country: country,
        city: city,
        createdAt: new Date().toISOString()
      };

      if (payload.sittingRoom == 0) {
        rental.houseType = 'single'
      } else if (payload.numberOfBedrooms == 1 && payload.sittingRoom >= 1) {
        rental.houseType = '1 bedroom and a sitting'
      } else if (payload.numberOfBedrooms == 2 && payload.sittingRoom >= 1) {
        rental.houseType = '2 bedrooms and a sitting'
      } else if (payload.numberOfBedrooms == 3 && payload.sittingRoom >= 1) {
        rental.houseType = '3 bedrooms and a sitting'
      } else if (payload.numberOfBedrooms >= 3 && payload.sittingRoom >= 1) {
        rental.houseType = 'family house'
      }


      const response = await fetch(
        `https://jazira-rentals-default-rtdb.firebaseio.com/rentals.json?auth=` +
        token,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(rental),
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        const error = new Error(
          responseData.message || "Failed to send request"
        );
        throw error;
      }


      // Adding imaages to firebase cloud storage
      // const storage = getStorage();
      // // console.log("This is everything",payload.image)
      // const imageUrls = []
      // for (const image of payload.image) {
      //     // console.log('this is the image',image)
      //     const storageRef = ref(storage, responseData.name + '/' + image.name);
      //     await uploadBytes(storageRef, image).then((snapshot) => {
      //         // console.log('Uploaded a blob or file!');
      //         console.log(snapshot)
      //       });

      //       const fileData = await getDownloadURL(ref(storage, responseData.name + '/' + image.name))
      //       imageUrls.push(fileData)
      //     //   console.log(fileData)
      // }

      // await fetch(`https://jazira-rentals-restore-default-rtdb.firebaseio.com/rentals/${responseData.name}.json?auth=` + token, {
      //     method: 'PATCH',
      //     body: JSON.stringify({
      //         imageUrls: imageUrls
      //     })
      // })


      // storing the images in bunny.net
      const bunnyImageUrls = []
      for (const image of payload.image) {

        const url = `https://jh.storage.bunnycdn.com/jazira-live/${responseData.name}/${image.name}`;
        const options = {
          method: 'PUT',
          headers: {
            AccessKey: 'f7123861-113f-4f6d-a7b8bea1f526-227d-43b1',
            'content-type': 'application/octet-stream'
          },
          body: image

        };

        const bunnyUpload = await fetch(url, options)
        // console.log('After using await', bunnyUpload)
        if (bunnyUpload.ok) {
          const pullZoneUrl = `https://jazira-pullzone-live.b-cdn.net/${responseData.name}/${image.name}`
          bunnyImageUrls.push(pullZoneUrl)
        }

      }

      // adding the pull zone urls
      await fetch(`https://jazira-rentals-default-rtdb.firebaseio.com/rentals/${responseData.name}.json?auth=` + token, {
        method: 'PATCH',
        body: JSON.stringify({
          bunnyImageUrls: bunnyImageUrls
        })
      })

      // const updatingallRentals = await fetch(
      //   `https://jazira-rentals-default-rtdb.firebaseio.com/rentals.json`
      // );

      // const updatingResponse = await updatingallRentals.json()
      // console.log('here', updatingResponse)
      // for (const key in updatingResponse) {
      //   console.log('The key', key)
      //     await fetch(`https://jazira-rentals-default-rtdb.firebaseio.com/rentals/${key}.json?auth=` + token, {
      //       method: 'PATCH',
      //       body: JSON.stringify({
      //         country: 'Uganda',
      //         city: 'Kampala'
      //       })
      //     })
      //   }



      // Updating all the apartments to have a houseType
      //    const updatingallRentals = await fetch(
      //   `https://jazira-rentals-restore-default-rtdb.firebaseio.com/rentals.json`
      // );

      // const updatingResponse = await updatingallRentals.json()
      // console.log('here', updatingResponse)
      // for (const key in updatingResponse) {
      //   console.log('The key', key)
      //   if (updatingResponse[key].numberOfBedrooms == 1 && updatingResponse[key].sittingRoom == 0) {
      //     await fetch(`https://jazira-rentals-restore-default-rtdb.firebaseio.com/rentals/${key}.json?auth=` + token, {
      //       method: 'PATCH',
      //       body: JSON.stringify({
      //         houseType: 'single'
      //       })
      //     })
      //   } 
        
      //   else if(updatingResponse[key].numberOfBedrooms == 1 && updatingResponse[key].sittingRoom >= 1) {
      //     await fetch(`https://jazira-rentals-restore-default-rtdb.firebaseio.com/rentals/${key}.json?auth=` + token, {
      //       method: 'PATCH',
      //       body: JSON.stringify({
      //         houseType: '1 bedroom and a sitting'
      //       })
      //     })
      //   }
      //   else if(updatingResponse[key].numberOfBedrooms == 2) {
      //     await fetch(`https://jazira-rentals-restore-default-rtdb.firebaseio.com/rentals/${key}.json?auth=` + token, {
      //       method: 'PATCH',
      //       body: JSON.stringify({
      //         houseType: '2 bedrooms and a sitting'
      //       })
      //     })
      //   }
      //   else if(updatingResponse[key].numberOfBedrooms == 3) {
      //     fetch(`https://jazira-rentals-restore-default-rtdb.firebaseio.com/rentals/${key}.json?auth=` + token, {
      //       method: 'PATCH',
      //       body: JSON.stringify({
      //         houseType: '3 bedrooms and a sitting'
      //       })
      //     }).then(result => {
      //       console.log("updated successfully houseType",result)
      //     }).catch(err => {
      //       console.log("error occured", err)
      //     })
      //   }
      //   else if(updatingResponse[key].numberOfBedrooms >= 4) {
      //     await fetch(`https://jazira-rentals-restore-default-rtdb.firebaseio.com/rentals/${key}.json?auth=` + token, {
      //       method: 'PATCH',
      //       body: JSON.stringify({
      //         houseType: 'Family house'
      //       })
      //     })
      //   }
      // }

      // const fetchingAllRentals = await fetch(
      //   `https://jazira-rentals-restore-default-rtdb.firebaseio.com/rentals.json`
      // );
      // let responseFetchData = await fetchingAllRentals.json();


      // if (!fetchingAllRentals.ok) {
      //   const error = new Error(responseFetchData.message || "Failed to fetch Data");
      //   throw error;
      // }

      // for (const key in responseFetchData) {
      //   // console.log(responseData[key]);
      //   const newBunnyImageUrls = []
      //   for(const imageKey in responseFetchData[key].imageUrls){
      //   console.log('This is the image url', responseFetchData[key].imageUrls[imageKey])
      //   console.log('This is the substring image url', responseFetchData[key].imageUrls[imageKey].substring(73))
      //   newBunnyImageUrls.push('https://firebase-jazira-live.b-cdn.net/' + responseFetchData[key].imageUrls[imageKey].substring(73))
      //   }
      //   await fetch(`https://jazira-rentals-restore-default-rtdb.firebaseio.com/rentals/${key}.json?auth=` + token, {
      //   method: 'PATCH',
      //   body: JSON.stringify({
      //     bunnyImageUrls: newBunnyImageUrls
      //   })
      // })


      // }

      // context.commit("addRental", rental)
    },
    async loadRentals(context) {
      const rentals = [];
      if (!context.getters.shouldUpdate) {
        return
      } else {
        const response = await fetch(
          `https://jazira-rentals-default-rtdb.firebaseio.com/rentals.json?orderBy="booked"&equalTo=false`
        );
        var responseData = await response.json();
        responseData = Object.values(responseData).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        // console.log('This is the length of the response data in users load', Object.keys(responseData).length)
        if (!response.ok) {
          const error = new Error(responseData.message || "Failed to fetch Data");
          throw error;
        }
        for (const key in responseData) {
          // console.log(responseData[key]);
          const rental = {
            // id: responseData[key].id,
            id: key,
            ownerName: responseData[key].ownerName,
            ownerPhoneNumber: responseData[key].ownerPhoneNumber,
            buildingName: responseData[key].buildingName,
            apartmentNumber: responseData[key].apartmentNumber,
            // imageUrls: responseData[key].imageUrls,
            bunnyImageUrls: responseData[key].bunnyImageUrls,
            description: responseData[key].description,
            emailAddress: responseData[key].emailAddress,
            location: responseData[key].location,
            price: parseInt(responseData[key].price),
            numberOfBedrooms: responseData[key].numberOfBedrooms,
            numberOfBathrooms: responseData[key].numberOfBathrooms,
            approved: responseData[key].approved,
            booked: responseData[key].booked,
            sittingRoom: responseData[key].sittingRoom,
            ownerId: responseData[key].ownerId,
            videoId: responseData[key].videoId,
            houseType: responseData[key].houseType,
            contactPersonName: responseData[key].contactPersonName,
            contactPersonPhone: responseData[key].contactPersonPhone,
            country: responseData[key].country,
            city: responseData[key].city,
            videoUrl: responseData[key].videoUrl,
          };
          rentals.unshift(rental);
        }
        rentals.sort((a, b) => {
          if (a.start_date < b.start_date) {
            return -1;
          }
          if (a.start_date > b.start_date) {
            return 1;
          }
          return 0;
        });
      }


      context.commit("setRentals", rentals);
      context.commit("setFetchTimestamp")
    },
  },
  getters: {
    properties(state) {
      return state.rentals;
    },
    rentalId(state) {
      // console.log(state.rentals)
      return state.rentals;
    },
    shouldUpdate(state) {
      const lastFetch = state.lastFetched
      if (!lastFetch) {
        return true;
      }
      const currentTimestamp = new Date().getTime()
      console.log('This is the first time', lastFetch)
      console.log('This is the last time', currentTimestamp)
      return (currentTimestamp - lastFetch) / 1000 > 3600
    }
  },
};
