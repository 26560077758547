import {createStore} from 'vuex'

import rentalModule from './modules/rentals/index'
import requestModule from './modules/requests/index'
import authModule from './modules/auth/index'
import shopModule from './modules/shops/index'
import adminModule from './modules/admin/index'
import marketplaceModule from './modules/marketplace/index'

const store = createStore({
    modules: {
        rentals: rentalModule,
        requests: requestModule,
        auth: authModule,
        shops: shopModule,
        admin: adminModule,
        marketplace: marketplaceModule
    },
})

export default store