export default {
  namespaced: true,
  state() {
    return {
      rentalRequests: [],
    };
  },
  mutations: {
    pushRequest(state, payload) {
      state.rentalRequests.push(payload);
    },
    setRequests(state, payload) {
        state.rentalRequests = payload
    }
  },
  actions: {
    async addRequest(context, payload) {
      // const ownerId = context.rootGetters.userId
      // console.log("This is the owner", ownerId)
      const newRequest = {
        ...payload,
      }
      const response = await fetch(
        `https://jazira-rentals-default-rtdb.firebaseio.com/requests.json`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(newRequest),
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        const error = new Error(
          responseData.message || "Failed to send request"
        );
        throw error;
      }
      context.commit("pushRequest", newRequest);
    },
    async loadRequests(context) {
      // const rentalId = context.rootGetters.rentalId
      const token = context.rootGetters.token
      const response = await fetch(
        `https://jazira-rentals-default-rtdb.firebaseio.com/requests.json?auth=` + token
      );
      const responseData = await response.json();
      if (!response.ok) {
        const error = new Error(
          responseData.message || "Failed to load request"
        );
        throw error;
      }
      const requests = [];
      for (const key in responseData) {
        const request = {
          id: key,
          firstName: responseData[key].firstName,
          lastName: responseData[key].lastName,
          phoneNumber: responseData[key].phoneNumber,
          emailAddress: responseData[key].emailAddress,
          rentalId: responseData[key].rentalId,
          bid: responseData[key].bid,
          ownerName: responseData[key].ownerName,
          ownerPhoneNumber: responseData[key].ownerPhoneNumber,
          propertyLocation: responseData[key].propertyLocation,
          buildingName: responseData[key].buildingName,
          apartmentNumber: responseData[key].apartmentNumber,
          type: responseData[key].type,
          date: responseData[key].date
        };
        requests.unshift(request);
      }
      context.commit("setRequests", requests);
    },
  },
  getters: {
    // requests(state, _, _2, rootGetters) {
    //   const ownerId = rootGetters.userId
    //   console.log(ownerId)
    //   // return state.requests.filter(req => req.ownerId === ownerId)
    //   // return state.requests.filter(function(req) {
    //   //   console.log(req.ownerId)
    //   // })
    //   return state.rentalRequests
    // },

    requests(state) {
      // const ownerId = rootGetters.userId
      // console.log(ownerId)
      // return state.requests.filter(req => req.ownerId === ownerId)
      // return state.requests.filter(function(req) {
      //   console.log(req.ownerId)
      // })
      return state.rentalRequests
    },
  },
};
