export default {
    namespaced: true,
    state() {
        return {
            lastFetchedAdmin: null,
            adminRentals: [],
            adminProducts: []
        }
    },
    mutations: {
        setAdminRentals(state, payload) {
            state.adminRentals = payload;
        },
        setAdminProducts(state, payload) {
            state.adminProducts = payload
        },
        setAdminFetchTimestamp(state) {
            state.lastFetchedAdmin = new Date().getTime()
        }
    },
    actions: {
        async loadAdminRentals(context) {
            const rentals = [];
            if (!context.getters.shouldUpdateAdmin) {
                return
            } else {
                const response = await fetch(
                    `https://jazira-rentals-default-rtdb.firebaseio.com/rentals.json`
                );
                const responseData = await response.json();
                // console.log('This is the length of the response data in users load', Object.keys(responseData).length)
                if (!response.ok) {
                    const error = new Error(responseData.message || "Failed to fetch Data");
                    throw error;
                }

                for (const key in responseData) {
                    // console.log(responseData[key]);
                    const rental = {
                        // id: responseData[key].id,
                        id: key,
                        ownerName: responseData[key].ownerName,
                        ownerPhoneNumber: responseData[key].ownerPhoneNumber,
                        buildingName: responseData[key].buildingName,
                        apartmentNumber: responseData[key].apartmentNumber,
                        bunnyImageUrls: responseData[key].bunnyImageUrls,
                        description: responseData[key].description,
                        emailAddress: responseData[key].emailAddress,
                        location: responseData[key].location,
                        price: parseInt(responseData[key].price),
                        numberOfBedrooms: responseData[key].numberOfBedrooms,
                        numberOfBathrooms: responseData[key].numberOfBathrooms,
                        approved: responseData[key].approved,
                        booked: responseData[key].booked,
                        sittingRoom: responseData[key].sittingRoom,
                        ownerId: responseData[key].ownerId,
                        videoId: responseData[key].videoId,
                        contactPersonName: responseData[key].contactPersonName,
                        contactPersonPhone: responseData[key].contactPersonPhone
                    };
                    rentals.unshift(rental);
                }
            }


            context.commit("setAdminRentals", rentals);
            context.commit("setAdminFetchTimestamp")
        },
        async approveRental(context, payload) {

            // Gets the online user's token
            const token = context.rootGetters.token;
            // console.log('When approving ', payload.id)
            const updateRental = await fetch(`https://jazira-rentals-default-rtdb.firebaseio.com/rentals/${payload.id}.json?auth=` + token, {
                method: 'PATCH',
                body: JSON.stringify({
                    approved: !payload.approved
                })
            })
            // const responseData = await updateRental.json()
            // console.log('response after updating', responseData)

            await updateRental.json()
        },
        async bookRental(context, payload) {
            const token = context.rootGetters.token;
            // console.log('This is the payload for the bookRental', payload)
            const updateRental = await fetch(`https://jazira-rentals-default-rtdb.firebaseio.com/rentals/${payload.id}.json?auth=` + token, {
                method: 'PATCH',
                body: JSON.stringify({
                    booked: !payload.booked,
                })
            })
            // const responseData = await updateRental.json()
            // console.log('response after updating booking', responseData)

            await updateRental.json()
        },

        async approveShop(context, payload) {
            const token = context.rootGetters.token;

            const updateShop = await fetch(`https://jazira-rentals-default-rtdb.firebaseio.com/shops/${payload.id}.json?auth=` + token, {
                method: 'PATCH',
                body: JSON.stringify({
                    approved: !payload.approved
                })
            })

            // const responseData = await updateShop.json() 
            await updateShop.json()
            // console.log('response after updating shops approval', responseData)
        },
        async bookShop(context, payload) {
            const token = context.rootGetters.token;

            const updateShop = await fetch(`https://jazira-rentals-default-rtdb.firebaseio.com/shops/${payload.id}.json?auth=` + token, {
                method: 'PATCH',
                body: JSON.stringify({
                    booked: !payload.booked
                })
            })

            // const responseData = await updateShop.json() 
            await updateShop.json()
            // console.log('response after updating shops booking', responseData)
        },
        async addVideoId(context, payload) {
            const token = context.rootGetters.token;
            const userEmail = context.rootGetters.userEmail

            const splited = payload.videoId.split("shorts/");
            const splitedAgain = splited[1].split("?");
            const videoId = splitedAgain[0]; 

            // console.log("The video Id is ", videoId)

            if(videoId.length >= 1) {
                await fetch(`https://jazira-rentals-default-rtdb.firebaseio.com/rentals/${payload.id}.json?auth=` + token, {
                method: 'PATCH',
                body: JSON.stringify({
                    videoId: videoId,
                    addedBy: userEmail
                })
            })
            } else {

                await fetch(`https://jazira-rentals-default-rtdb.firebaseio.com/rentals/${payload.id}.json?auth=` + token, {
                method: 'PATCH',
                body: JSON.stringify({
                    videoId: payload.videoId,
                    addedBy: userEmail
                })
            })

            }

            

            
        },
        async addContactPersonInfo(context, payload) {
            const token = context.rootGetters.token
            // console.log("This is the id", payload.id)
            fetch(`https://jazira-rentals-default-rtdb.firebaseio.com/rentals/${payload.id}.json?auth=` + token, {
                method: 'PATCH',
                body: JSON.stringify({
                    contactPersonName: payload.contactPersonName,
                    contactPersonPhone: payload.contactPersonPhone
                })
            }).then(result => {
                console.log(result)
            })
                .catch(err => {
                    console.log(err)
                })

            // const result = await updateProperty.json()

            // console.log(result.ok)
        },
        async loadAdminProducts(context) {
            const products = []
            if (!context.getters.shouldUpdateAdmin) {
                return
            } else {
                const response = await fetch('https://jazira-rentals-default-rtdb.firebaseio.com/marketplace/products.json')
                const responseData = await response.json()
                if (!response.ok) {
                    const error = new Error(
                        response.message || 'Failed to fetch products'
                    )
                    throw error
                }
                
                for (const key in responseData) {
                    const product = {
                        id: key,
                        ownerName: responseData[key].ownerName,
                        ownerPhoneNumber: responseData[key].ownerPhoneNumber,
                        ownerEmailAddress: responseData[key].ownerEmailAddress,
                        ownerId: responseData[key].ownerId,
                        productName: responseData[key].productName,
                        description: responseData[key].description,
                        price: parseInt(responseData[key].price),
                        approved: responseData[key].approved,
                        sold: responseData[key].sold,
                        bunnyImageUrls: responseData[key].bunnyImageUrls,
                        videoId: responseData[key].videoId,
                        country: responseData[key].country,
                        city: responseData[key].city,
                        createdAt: responseData[key].createdAt
                    }
                    products.unshift(product)
                }
            }
            // console.log('before setting up', products)
            context.commit("setAdminProducts", products);
            context.commit("setAdminFetchTimestamp")

        },
        async approveProduct(context, payload) {

            // Gets the online user's token
            const token = context.rootGetters.token;
            // console.log('When approving ', payload.id)
            const updateRental = await fetch(`https://jazira-rentals-default-rtdb.firebaseio.com/marketplace/products/${payload.id}.json?auth=` + token, {
                method: 'PATCH',
                body: JSON.stringify({
                    approved: !payload.approved
                })
            })
            // const responseData = await updateRental.json()
            // console.log('response after updating', responseData)

            await updateRental.json()
        },
        async sellProduct(context, payload) {
            const token = context.rootGetters.token;
            // console.log('This is the payload for the bookRental', payload)
            const updateRental = await fetch(`https://jazira-rentals-default-rtdb.firebaseio.com/marketplace/products/${payload.id}.json?auth=` + token, {
                method: 'PATCH',
                body: JSON.stringify({
                    sold: !payload.sold,
                })
            })
            // const responseData = await updateRental.json()
            // console.log('response after updating booking', responseData)

            await updateRental.json()
        },
        async addVideoIdToMarketProduct(context, payload) {
            const token = context.rootGetters.token;

            await fetch(`https://jazira-rentals-default-rtdb.firebaseio.com/marketplace/products/${payload.id}.json?auth=` + token, {
                method: 'PATCH',
                body: JSON.stringify({
                    videoId: payload.videoId
                })
            })
        },
    },
    getters: {
        adminProperties(state) {
            return state.adminRentals;
        },
        adminProducts(state) {
            return state.adminProducts
        },
        shouldUpdateAdmin(state) {
            const lastFetch = state.lastFetchedAdmin
            if (!lastFetch) {
                return true;
            }
            const currentTimestamp = new Date().getTime()
            // console.log('This is the first time', lastFetch)
            // console.log('This is the last time', currentTimestamp)
            return (currentTimestamp - lastFetch) / 1000 > 3600
        }
    }
}