<template>
  <the-header></the-header>
  <the-intro class="myClass"></the-intro>
  <div v-if="isLoading">
    <base-spinner></base-spinner>
  </div>
  <router-view v-else v-slot="slotProps">
    <transition name="route" mode="out-in">
      <component :is="slotProps.Component"></component>
    </transition>
  </router-view>
</template>

<script>
import TheHeader from "./components/layout/TheHeader.vue";
import TheIntro from "./components/layout/TheIntro.vue";
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  components: {
    TheHeader,
    TheIntro,
  },
  async created() {
    this.isLoading = true;
    await this.$store.dispatch("rentals/loadRentals");
    await this.$store.dispatch("marketplace/loadProducts");
    this.isLoading = false;
    this.$store.dispatch("tryLogin");
  },

  //  created() {
  //   this.$store.dispatch('tryLogin')
  // },
  computed: {
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    },
  },
  watch: {
    didAutoLogout(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        this.$router.replace("/rentals");
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
} */
* {
  box-sizing: border-box;
}

html {
  font-family: "Roboto", sans-serif;
  /* font-family: "Ivar Headline","Times New Roman",serif; */
}

body {
  margin: 0;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.route-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.route-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}
.myClass {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
</style>
