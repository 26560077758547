import { getStorage, ref, uploadBytes, getDownloadURL  } from "firebase/storage";


export default {
  namespaced: true,
  state() {
    return {
      shops: [],
    };
  },
  mutations: {
    addShop(state, payload) {
      state.shops.push(payload);
    },
    setShops(state, payload) {
      state.shops = payload;
    },
  },
  actions: {
    async addShop(context, payload) {
      // get the owner who is adding the shop / office
      const userId = context.rootGetters.userId;
      const email = context.rootGetters.userEmail

      const token = context.rootGetters.token;

      const ownerDetails = await fetch('https://jazira-rentals-default-rtdb.firebaseio.com/owners.json?auth=' + token)

      const responseOwnerDetailsData = await ownerDetails.json()

      // console.log('all owners',responseOwnerDetailsData)

      let owner = null
      for (const ownerKey in responseOwnerDetailsData) {
        if (responseOwnerDetailsData[ownerKey].email === email) {
          owner = responseOwnerDetailsData[ownerKey]
        }
      }
      // console.log('this is the owner', owner)

      const shop = {
        ownerName: owner.firstName + ' ' + owner.lastName,
        ownerPhoneNumber: owner.phoneNumber,
        buildingName: payload.buildingName,
        shopNumber: payload.shopNumber,
        description: payload.description,
        price: payload.price,
        location: payload.location,
        emailAddress: email,
        ownerId: userId,
        approved: payload.approved,
        booked: payload.booked
      };

      

      const response = await fetch(
        `https://jazira-rentals-default-rtdb.firebaseio.com/shops.json?auth=` +
        token,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(shop),
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        const error = new Error(
          responseData.message || "Failed to send request"
        );
        throw error;
      }
      // context.commit("addRental", {
      //   ...rental,
      //   id: userId
      // });


      // console.log('this is the response Data', responseData.name)
            const storage = getStorage();
            // console.log("This is everything",payload.image)


            const imageUrls = []
            for (const image of payload.image) {
                // console.log('this is the image',image)
                const storageRef = ref(storage, responseData.name + '/' + image.name);
                await uploadBytes(storageRef, image).then((snapshot) => {
                    // console.log('Uploaded a blob or file!');
                    console.log(snapshot)
                  });

                  const fileData = await getDownloadURL(ref(storage, responseData.name + '/' + image.name))
                  imageUrls.push(fileData)
                //   console.log(fileData)
            }

            // console.log('sdlkfj',responseData.name)

            // const updateResponse = await fetch(`https://jazira-rentals-default-rtdb.firebaseio.com/shops/${responseData.name}.json?auth=` + token, {
            //     method: 'PATCH',
            //     body: JSON.stringify({
            //         imageUrls: imageUrls
            //     })
            // })

            // console.log(updateResponse)

            await fetch(`https://jazira-rentals-default-rtdb.firebaseio.com/shops/${responseData.name}.json?auth=` + token, {
                method: 'PATCH',
                body: JSON.stringify({
                    imageUrls: imageUrls
                })
            })

            

      // context.commit("addRental", rental)
    },
    async loadShops(context) {
      const response = await fetch(
        `https://jazira-rentals-default-rtdb.firebaseio.com/shops.json`
      );
      const responseData = await response.json();
      if (!response.ok) {
        const error = new Error(responseData.message || "Failed to fetch Data");
        throw error;
      }
      const shops = [];
      for (const key in responseData) {
        // console.log(responseData[key]);
        const shop = {
          // id: responseData[key].id,
          id:key,
          ownerName: responseData[key].ownerName,
          ownerPhoneNumber: responseData[key].ownerPhoneNumber,
          shopNumber: responseData[key].shopNumber,
          buildingName: responseData[key].buildingName,
          imageUrls: responseData[key].imageUrls,
          description: responseData[key].description,
          emailAddress: responseData[key].emailAddress,
          location: responseData[key].location,
          price: parseInt(responseData[key].price),
          approved: responseData[key].approved,
          booked: responseData[key].booked
        };
        shops.unshift(shop);
      }
      context.commit("setShops", shops);
    },
  },
  getters: {
    properties(state) {
        // console.log(state.shops)
      return state.shops;
    },
    shopId(state) {
      // console.log(state.rentals)
      return state.shops;
    },
  },
};
