export default {
    namespaced: true,
    state() {
        return {
            lastFetched: null,
            products: [],
            productRequests: []
        }
    },
    mutations: {
        // addProduct(state, payload) {

        // },
        setProducts(state, payload) {
            state.products = payload
        },
        setMarketplaceRequests(state, payload) {
            state.productRequests = payload
        },
        setFetchTimestamp(state) {
            state.lastFetched = new Date().getTime()
        }
    },
    actions: {
        async addProduct(context, payload) {
            // get the details of owner who is adding the rental and who is online currently
            const userId = context.rootGetters.userId;
            const email = context.rootGetters.userEmail


            const token = context.rootGetters.token;

            const ownerDetails = await fetch('https://jazira-rentals-default-rtdb.firebaseio.com/owners.json?auth=' + token)

            const responseOwnerDetailsData = await ownerDetails.json()

            // console.log('all owners', responseOwnerDetailsData)

            let owner = null
            // console.log('The email', email)
            for (const ownerKey in responseOwnerDetailsData) {
                if (responseOwnerDetailsData[ownerKey].email === email) {
                    owner = responseOwnerDetailsData[ownerKey]
                }
            }
            // console.log('this is the owner', owner)
            let country = 'Uganda'
            let city = 'Kampala'

            if (payload.country) {
                country = payload.country
            }

            if (payload.city) {
                city = payload.city
            }
            const product = {
                ownerName: owner.firstName + ' ' + owner.lastName,
                ownerPhoneNumber: owner.phoneNumber,
                ownerEmailAddress: email,
                ownerId: userId,
                productName: payload.productName,
                description: payload.description,
                price: payload.price,
                approved: payload.approved,
                sold: payload.sold,
                country: country,
                city: city,
                createdAt: new Date().toISOString()
            }

            const response = await fetch(
                'https://jazira-rentals-default-rtdb.firebaseio.com/marketplace/products.json?auth=' + token,
                {
                    method: 'POST',
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(product)
                }
            )

            const responseData = await response.json()
            if (!response.ok) {
                const error = new Error(
                    response.message || 'Failed to Post product'
                )
                throw error
            }

            // storing the images in bunny.net
            const bunnyImageUrls = []
            for (const image of payload.image) {

                const url = `https://jh.storage.bunnycdn.com/jazira-live/marketplace/products/${responseData.name}/${image.name}`;
                const options = {
                    method: 'PUT',
                    headers: {
                        AccessKey: 'f7123861-113f-4f6d-a7b8bea1f526-227d-43b1',
                        'content-type': 'application/octet-stream'
                    },
                    body: image

                };

                const bunnyUpload = await fetch(url, options)
                // console.log('After using await', bunnyUpload)
                if (bunnyUpload.ok) {
                    const pullZoneUrl = `https://jazira-pullzone-live.b-cdn.net/marketplace/products/${responseData.name}/${image.name}`
                    bunnyImageUrls.push(pullZoneUrl)
                }

            }

            // adding the pull zone urls
            await fetch(`https://jazira-rentals-default-rtdb.firebaseio.com/marketplace/products/${responseData.name}.json?auth=` + token, {
                method: 'PATCH',
                body: JSON.stringify({
                    bunnyImageUrls: bunnyImageUrls
                })
            })

        },
        async loadProducts(context) {
            const products = []
            if (!context.getters.shouldUpdate) {
                return
            } else {
                const response = await fetch('https://jazira-rentals-default-rtdb.firebaseio.com/marketplace/products.json')
                const responseData = await response.json()
                if (!response.ok) {
                    const error = new Error(
                        response.message || 'Failed to fetch products'
                    )
                    throw error
                }
                
                for (const key in responseData) {
                    const product = {
                        id: key,
                        ownerName: responseData[key].ownerName,
                        ownerPhoneNumber: responseData[key].ownerPhoneNumber,
                        ownerEmailAddress: responseData[key].ownerEmailAddress,
                        ownerId: responseData[key].ownerId,
                        productName: responseData[key].productName,
                        description: responseData[key].description,
                        price: parseInt(responseData[key].price),
                        approved: responseData[key].approved,
                        sold: responseData[key].sold,
                        bunnyImageUrls: responseData[key].bunnyImageUrls,
                        videoId: responseData[key].videoId,
                        country: responseData[key].country,
                        city: responseData[key].city,
                        createdAt: responseData[key].createdAt
                    }
                    products.unshift(product)
                }
            }
            // console.log('before setting up', products)
            context.commit("setProducts", products);
            context.commit("setFetchTimestamp")

        },
        async addProductRequest(_, payload) {
            // const ownerId = context.rootGetters.userId
            // console.log("This is the owner", ownerId)
            const newRequest = {
              ...payload,
            }
            const response = await fetch(
              `https://jazira-rentals-default-rtdb.firebaseio.com/marketplace/productRequests.json`,
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(newRequest),
              }
            );
            const responseData = await response.json();
            if (!response.ok) {
              const error = new Error(
                responseData.message || "Failed to send request"
              );
              throw error;
            }
        },
        async loadProductRequests(context) {
        // const rentalId = context.rootGetters.rentalId
        const token = context.rootGetters.token
        const response = await fetch(
            `https://jazira-rentals-default-rtdb.firebaseio.com/marketplace/productRequests.json?auth=` + token
        );
        const responseData = await response.json();
        if (!response.ok) {
            const error = new Error(
            responseData.message || "Failed to load request"
            );
            throw error;
        }
        const requests = [];
        for (const key in responseData) {
            const request = {
            id: key,
            firstName: responseData[key].firstName,
            lastName: responseData[key].lastName,
            phoneNumber: responseData[key].phoneNumber,
            emailAddress: responseData[key].emailAddress,
            productId: responseData[key].productId,
            ownerName: responseData[key].ownerName,
            ownerPhoneNumber: responseData[key].ownerPhoneNumber,
            productName: responseData[key].productName,
            price: responseData[key].price,
            type: responseData[key].type,
            date: responseData[key].date
            };
            requests.unshift(request);
        }
        context.commit("setMarketplaceRequests", requests);
        },
    },
    getters: {
        getProducts(state) {
            return state.products
        },
        getProductRequests(state) {
            return state.productRequests
        },
        shouldUpdate(state) {
            const lastFetch = state.lastFetched
            if (!lastFetch) {
              return true;
            }
            const currentTimestamp = new Date().getTime()
            // console.log('This is the first time', lastFetch)
            // console.log('This is the last time', currentTimestamp)
            return (currentTimestamp - lastFetch) / 1000 > 3600
        }
    }
}