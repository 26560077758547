<template>
  <div class="head">
    <!-- <nav class="navbar navbar-default navbar-static-top navbar-custom navbar-expand-lg navbar-light fixed-top"> -->
    <nav
      class="
        navbar
        navbar-expand-lg
        navbar-light
        navbar-default
        navbar-static-top
        navbar-custom
        fixed-top
        shadow-sm p-2 mb-5 bg-white rounded
      "
    >
        <router-link class="navbar-brand" to="/" @click="toggleVisibility"
          ><img src="../../../public/jazira.png" alt="" /> Jazira
          Rentals</router-link
        >
        <button
          class="navbar-toggler hidden-sm-up lightiningColor"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="toggleNav"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="navbar-collapse" :class="visible ? '' : 'collapse'"  id="navbarSupportedContent">
          <ul class="navbar-nav mymargin">

            <li
              class="nav-item"
              @click="toggleVisibility"
              v-if="isLoggedIn && (adminEmail || subAdmin)"
            >
              <router-link
                class="nav-link"
                to="/requests"
                style="color: black"
                @click="toggleVisibility"
                >Rental Requests</router-link
              >
            </li>

            <li
              class="nav-item"
              @click="toggleVisibility"
              v-if="isLoggedIn && (adminEmail || subAdmin)"
            >
              <router-link
                class="nav-link"
                to="/marketplaceRequests"
                style="color: black"
                @click="toggleVisibility"
                >Maketplace Requests</router-link
              >
            </li>

            <li
              class="nav-item"
              v-if="isLoggedIn"
            >
              <router-link
                class="nav-link"
                to="/registerRental"
                style="color: black"
                @click="toggleVisibility"
                >Register a Rental</router-link
              >
            </li>
            <li
              class="nav-item"
              v-if="isLoggedIn"
            >
              <router-link
                class="nav-link"
                to="/registerCommercial"
                style="color: black"
                @click="toggleVisibility"
                >Register a shop/office</router-link
              >
            </li>

            <li
              class="nav-item"
              v-if="isLoggedIn"
            >
              <router-link
                class="nav-link"
                to="/marketplace/addProduct"
                style="color: black"
                @click="toggleVisibility"
                >Sell a Product</router-link
              >
            </li>

            <li
              class="nav-item"
              v-if="isLoggedIn"
            >
              <router-link
                class="nav-link"
                @click="toggleVisibility"
                :to="userAdminPage"
                style="color: black"
                >Manage your properties</router-link
              >
            </li>
            <li
              v-if="isLoggedIn && adminEmail"
              class="nav-item"
            >
              <router-link
                class="nav-link"
                style="color: black"
                @click="toggleVisibility"
                to="/rentalAdmin"
                >Rental Admin</router-link
              >
            </li>
            <li
              v-if="isLoggedIn && adminEmail"
              class="nav-item"
            >
              <router-link
                class="nav-link"
                style="color: black"
                @click="toggleVisibility"
                to="/marketplaceAdminPage"
                >Market Admin</router-link
              >
            </li>
            <li
              v-if="isLoggedIn && adminEmail"
              
              class="nav-item"
            >
              <router-link
                class="nav-link"
                style="color: black"
                @click="toggleVisibility"
                to="/shopAdmin"
                >Shop Admin</router-link
              >
            </li>
            <li
              class="nav-item"
              v-if="!isLoggedIn"
            >
              <router-link
                class="nav-link"
                style="color: black"
                @click="toggleVisibility"
                to="/auth"
                
                >Login as a landlord</router-link
              >
            </li>

            <li
              v-if="isLoggedIn"
              class="nav-item"
              style="color: black"
              @click="toggleVisibility"
            >
              <base-button
                @click="logout"
                >Logout</base-button
              >
            </li>
          </ul>
        </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      admins: [
        "clahimoha1000@gmail.com",
        "abdigoley66@gmail.com",
        'gareeda2022@gmail.com'
      ]
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    adminEmail() {
      return this.$store.getters.userEmail === "clahimoha1000@gmail.com" || 
      this.$store.getters.userEmail === "abdigoley66@gmail.com" || 
      this.$store.getters.userEmail == 'mohamedj@gmail.com' ||
      this.$store.getters.userEmail === "abdirizakmohammed10@gmail.com";
      
    },
    subAdmin() {
      return this.$store.getters.userEmail === "abdirizakmohammed10@gmail.com" ||
      // this.$store.getters.userEmail === 'gareeda2022@gmail.com' ||
      this.$store.getters.userEmail == 'mohamedj@gmail.com';
    },
    userAdminPage() {
      return "/myRentals/" + this.$store.getters.userId;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace("/");
    },
    toggleNav() {
      this.visible = !this.visible
    },
    toggleVisibility() {
      this.visible = false
    }
  },
};
</script>

<style scoped>
.navbar-custom {
  /* background-color: #3d008d; */
  background-color: white;
}
.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
  /* color: white; */
  color: black;
}
.mymargin {
  margin-left: auto;
}
.head {
  margin-bottom: 5rem;
}
.head > ul {
  margin-right: auto;
}
li {
  /* color: white; */
  color: black;
}
.lightiningColor {
  background-color: rgb(151, 146, 146);
}
img {
  width: 20%;
  height: 18%;
}

</style>