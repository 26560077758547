let timer;

export default {
  async login(context, payload) {
    // you can dispatch an action within an action
    return context.dispatch('auth', {
      ...payload,
      mode: 'login'
    });
  },
  async signup(context, payload) {
    return context.dispatch('auth', {
      ...payload,
      mode: 'signup'
    });
  },
  async auth(context, payload) {
    const mode = payload.mode;
    let url = `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=
    AIzaSyA9V12RvWwkQp6Povy4zjSpkKIEsoYR4LA`;
    if (mode === 'signup') {
      url = `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=
      AIzaSyA9V12RvWwkQp6Povy4zjSpkKIEsoYR4LA`;
    }
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        email: payload.email,
        password: payload.password,
        returnSecureToken: true,
      })
    });

    const responseData = await response.json();

    if (mode === 'signup') {
      const ownerRegistrationResponse = await fetch('https://jazira-rentals-default-rtdb.firebaseio.com/owners.json', {
      method: 'POST',
      body: JSON.stringify({
        firstName: payload.firstName,
        lastName: payload.lastName,
        phoneNumber: payload.phoneNumber,
        email: payload.email,
        password: payload.password
      })
    });

      // const ownerResponseData = await ownerRegistrationResponse.json()

      await ownerRegistrationResponse.json()

      if (!ownerRegistrationResponse.ok) {
        const error = new Error(responseData.message || 'Failed to authenticate');
        throw error;
      } else {
        // console.log('this is the owner response data',ownerResponseData)
      }
    
    }

    if (!response.ok) {
      const error = new Error(responseData.message || 'Failed to authenticate');
      throw error;
    }

    


    const expiresIn = +responseData.expiresIn * 1000;
    // const expiresIn = 5000
    const expirationDate = new Date().getTime() + expiresIn;

    localStorage.setItem('token', responseData.idToken);
    localStorage.setItem('userId', responseData.localId);
    localStorage.setItem('email', responseData.email)
    localStorage.setItem('tokenExpiration', expirationDate);

    timer = setTimeout(function() {
      context.dispatch('autoLogout');
    }, expiresIn);

    context.commit('setUser', {
      token: responseData.idToken,
      userId: responseData.localId,
      email: responseData.email,
    });
  },
  tryLogin(context) {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const email = localStorage.getItem('email')
    const tokenExpiration = localStorage.getItem('tokenExpiration');

    const expiresIn = +tokenExpiration - new Date().getTime();

    if (expiresIn < 0) {
      return;
    }

    timer = setTimeout(function() {
        context.dispatch('autoLogout')
    },expiresIn)

    if (token && userId) {
      context.commit('setUser', {
        token: token,
        userId: userId,
        email: email
      });
    }
  },
  logout(context) {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('email')
    localStorage.removeItem('tokenExpiration');

    clearTimeout(timer);

    context.commit('setUser', {
      token: null,
      userId: null,
      email: null
    });
  },
  autoLogout(context) {
    context.dispatch('logout')
    context.commit('setAutoLogout')
  }
};
