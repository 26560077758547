import { createRouter, createWebHistory } from "vue-router";

// import RentalList from "./pages/rentals/RentalList";
// const HomePage = () => import("./pages/home/HomePage")
const RentalList = () => import("./pages/rentals/RentalList")
const RentalDetails = () => import("./pages/rentals/RentalDetails")
const BookRental = () => import("./pages/requests/BookRental")
const ProductList = () => import("./pages/marketplace/productList")
const ProductDetail = () => import("./pages/marketplace/productDetail")
const BuyProduct = () => import("./pages/requests/BuyProduct")
const addProduct = () => import("./pages/marketplace/addProductForm")
const MarketplaceRequests = () => import("./pages/requests/MarketplaceRequests")
const BookShop = () => import("./pages/requests/BookRental")
const RequestList = () => import("./pages/requests/RequestList")
const RentalRegistration = () => import("./pages/rentals/RentalRegistration")
const ShopRegistration = () => import('./pages/shops/ShopRegistration')
const ShopList = () => import('./pages/shops/ShopList')
const ShopDetail = () => import('./pages/shops/ShopDetail')

const RentalAdminPage = () => import('./pages/admin/RentalAdminPage')
const UserAdminPage = () => import('./pages/admin/UserAdminPage')
const ShopAdminPage = () => import('./pages/admin/ShopAdminPage')

const MarketplaceAdminPage = () => import("./pages/admin/marketplace/marketplaceAdminPage")


const NotFound = () => import('./pages/NotFound')
const UserAuth = () => import("./pages/auth/UserAuth")

import NProgress from 'nprogress';

import store from "./store/index";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/github-pages", redirect: '/' },
    // { path: "/", component: HomePage },
    { path: "/", redirect: "/rentals"},
    {
      name: 'rentals',
      path: "/rentals",
      component: RentalList
    },
    {
      name: "rentalDetail",
      path: "/rentals/:id",
      component: RentalDetails,
      props: true,
      children: [{ name: 'registerRequest', path: "contact", component: BookRental }],
    },
    {
      name: "registerRental",
      path: "/registerRental",
      component: RentalRegistration,
      meta: { requiresAuth: true },
    },
    {
      name: "products",
      path: '/marketplace/products',
      component: ProductList
    },
    {
      name: "addProduct",
      path: '/marketplace/addProduct',
      component: addProduct,
      meta: { requiresAuth: true }
    },
    {
      name: "productDetail",
      path: "/marketplace/product/:id",
      component: ProductDetail,
      props: true,
      children: [{ name: 'BuyProduct', path: "buy", component: BuyProduct }],
    },
    {
      name: "registerCommercial",
      path: "/registerCommercial",
      component: ShopRegistration,
      meta: { requiresAuth: true },
    },
    {
      name: "Offices&Shops",
      path: "/Offices&Shops",
      component: ShopList
    },
    {
      path: "/offices&Shops/:id",
      component: ShopDetail,
      props: true,
      children: [{ path: "contact", component: BookShop }],
    },
    {
      name: "requests",
      path: "/requests",
      component: RequestList,
      beforeEnter: () => {
        if (store.getters.userEmail == 'clahimoha1000@gmail.com' || 
          store.getters.userEmail == 'abdigoley66@gmail.com' || 
          store.getters.userEmail == 'abdirizakmohammed10@gmail.com' || 
          store.getters.userEmail === "abdirizakmohammed10@gmail.com" ||
          store.getters.userEmail == 'mohamedj@gmail.com') {
          // console.log(to, from)
          // console.log(store.getters.userEmail)
          return true
        } else {
          return { name: 'rentals' }
        }

      },
      meta: { requiresAuth: true }
    },
    {
      name: "marketplaceRequests",
      path: "/marketplaceRequests",
      component: MarketplaceRequests,
      beforeEnter: () => {
        if (store.getters.userEmail == 'clahimoha1000@gmail.com' || 
          store.getters.userEmail == 'abdigoley66@gmail.com' || 
          store.getters.userEmail == 'abdirizakmohammed10@gmail.com' || 
          store.getters.userEmail === "abdirizakmohammed10@gmail.com" ||
          store.getters.userEmail == 'mohamedj@gmail.com') {
          // console.log(to, from)
          // console.log(store.getters.userEmail)
          return true
        } else {
          return { name: 'products' }
        }

      },
      meta: { requiresAuth: true }
    },
    {
      name: "auth",
      path: "/auth",
      component: UserAuth,
      beforeEnter: () => {
        if (store.getters.isAuthenticated) {
          return { name: 'rentals' }
        } else {
          // console.log(to, from)
          // console.log(store.getters.userEmail)
          return true
        }

      },
      meta: { requiresUnAuth: true },
    },
    {
      name: 'rentalAdmin',
      path: '/rentalAdmin',
      component: RentalAdminPage,
      beforeEnter: () => {
        if (store.getters.userEmail === 'clahimoha1000@gmail.com' || store.getters.userEmail === 'abdigoley66@gmail.com' || store.getters.userEmail === 'abdirizakmohammed10@gmail.com' || store.getters.userEmail === 'mohamedj@gmail.com') {
          // console.log(to, from)
          // console.log(store.getters.userEmail)
          return true
        } else {
          return { name: 'rentals' }
        }

      },
      meta: { requiresAuth: true }
    },
    {
      name: 'marketplaceAdminPage',
      path: '/marketplaceAdminPage',
      component: MarketplaceAdminPage,
      beforeEnter: () => {
        if (store.getters.userEmail == 'clahimoha1000@gmail.com' || store.getters.userEmail == 'abdigoley66@gmail.com' || store.getters.userEmail == 'abdirizakmohammed10@gmail.com' || store.getters.userEmail == 'mohamedj@gmail.com') {
          // console.log(to, from)
          // console.log(store.getters.userEmail)
          return true
        } else {
          return { name: 'products' }
        }

      },
      meta: { requiresAuth: true }
    },
    {
      name: 'shopAdmin',
      path: '/shopAdmin',
      component: ShopAdminPage,
      beforeEnter: (to, from) => {
        if (store.getters.userEmail == 'clahimoha1000@gmail.com' || store.getters.userEmail == 'abdigoley66@gmail.com' || store.getters.userEmail == 'mohamedj@gmail.com') {
          console.log(to, from)
          console.log(store.getters.userEmail)
          return true
        } else {
          return { name: 'Offices&Shops' }
        }

      },
      meta: { requiresAuth: true }
    },
    {
      path: "/myRentals/:userId",
      component: UserAdminPage,
      props: true,
    },
    { path: '/:notFound(.*)', component: NotFound }
  ],
  scrollBehavior(to) {
    // return desired position
    if (to.name == "registerRequest" || to.name == "rentalDetail" || to.name == 'productDetail' || to.name == 'BuyProduct') {
      return { top: 0 }
    }
  }
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.beforeEach((to) => {
  // console.log(store.getters.isAuthenticated)
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    // console.log('This is from router', store.getters.isAuthenticated, 'its from ', from)

    return { name: 'auth' }
  } else if (to.meta.requiresUnAuth && store.getters.isAuthenticated) {
    // console.log('This is from router for the auth', store.getters.isAuthenticated)

    return { name: 'rentals' }
  } else {
    return true
  }
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})


export default router;
