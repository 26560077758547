import { render } from "./TheIntro.vue?vue&type=template&id=3bf3148c&scoped=true"
import script from "./TheIntro.vue?vue&type=script&lang=js"
export * from "./TheIntro.vue?vue&type=script&lang=js"

import "./TheIntro.vue?vue&type=style&index=0&id=3bf3148c&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-3bf3148c"
/* custom blocks */
import block0 from "./TheIntro.vue?vue&type=custom&index=0&blockType=li&class=nav-item"
if (typeof block0 === 'function') block0(script)


export default script