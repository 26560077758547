import { createApp } from 'vue'
// import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import '../node_modules/nprogress/nprogress.css' 
import router from './router'
import store from './store/index'

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

import App from './App.vue'

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
// import 'jquery'
// import 'popper.js'
// import '@popperjs/core'
// import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css'

// import "bootstrap/dist/css/bootstrap.min.css"

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import 'bootstrap-icons/font/bootstrap-icons.css'

import BaseCard from './components/ui/BaseCard'
import BaseButton from './components/ui/BaseButton'
import BaseSpinner from './components/ui/BaseSpinner'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA9V12RvWwkQp6Povy4zjSpkKIEsoYR4LA",
  authDomain: "jazira-rentals.firebaseapp.com",
  projectId: "jazira-rentals",
  storageBucket: "gs://jazira-rentals.appspot.com",
  messagingSenderId: "428192826797",
  appId: "1:428192826797:web:a0edc47a56cb69261bcd24"
};

// Initialize Firebase
initializeApp(firebaseConfig);

const app = createApp(App)

// app.use(BootstrapVue)
// app.use(BootstrapVueIcons)

app.use(router)
app.use(store)

app.component('base-card', BaseCard)
app.component('base-button', BaseButton)
app.component('base-spinner', BaseSpinner)

app.mount('#app')
