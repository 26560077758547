<template>
  <div class="container theWholeContainer">
    <ul class="nav nav-tabs justify-content-around">
      <li class="nav-item">
        <router-link @click="activeLink('houses')" class="nav-link" to="/rentals" :class="activeMode === 'houses' ? 'active' : ''">
          <i class="fa-solid fa-house fa-lg" id="rent"></i>
          <p>Rental's</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link @click="activeLink('marketplace')" class="nav-link" to="/marketplace/products" :class="activeMode === 'marketplace' ? 'active' : ''">
          <i class="bi bi-cart4" id="market"></i>
          <p>Marketplace</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link @click="activeLink('offices')" class="nav-link" to="/offices&shops" :class="activeMode === 'offices' ? 'active' : ''">
          <i class="fa-solid fa-building-circle-check fa-lg" id="office"></i>
          <p>Offices</p>
        </router-link>
      </li>
    </ul>
    <!-- <h1>This is when is dockerised</h1> -->
  </div>
</template>

<li class="nav-item">
    
    
</li>

<script>
export default {
    data() {
        return {
            activeMode: this.$route.path
        }
    },
    methods: {
        activeLink(mode) {
            this.activeMode = mode
        }
    },
};
</script>

<style scoped>
li {
  list-style: none;
  color: white;
}

#rent {
    margin-left: .8rem;
}

#office {
    margin-left: .8rem;
}

#market {
    margin-left: 2.4rem;
}

a { 
    color: gray;
}
.theWholeContainer {
  margin-left: auto;
  margin-bottom: .5rem;
}


</style>